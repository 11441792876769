import { render, staticRenderFns } from "./DishesListItem.vue?vue&type=template&id=2cef2242&scoped=true&"
import script from "./DishesListItem.vue?vue&type=script&lang=js&"
export * from "./DishesListItem.vue?vue&type=script&lang=js&"
import style0 from "./DishesListItem.vue?vue&type=style&index=0&id=2cef2242&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cef2242",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
